import React from 'react'
import Router from './router';

function App() {
  return (
    <div className="App">
     <Router/>
     
    </div>
  );
}

export default App;
