import React from 'react';
import './chat.css';

function ChatbotFooter() {
  return (
    
    <>
    
    <a href="https://api.whatsapp.com/send/?phone=5511919267633&text&app_absent=0" class="float" target="_blank">
    <i class="fa fa-whatsapp my-float"></i>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
    <style>
      
    </style>
  </a>

  
  
    </>
   
  );
}

export default ChatbotFooter;